export const DEVICE_TYPE__MOBILE = 1
export const DEVICE_TYPE__DESKTOP = 2

export const DEVICE_TYPE_SELECT = [
  {
    id: DEVICE_TYPE__MOBILE,
    name: 'Mobile'
  },
  {
    id: DEVICE_TYPE__DESKTOP,
    name: 'Desktop'
  },
]

export const RECOMMENDATIONS__LINK__GLOBAL = 'global'
