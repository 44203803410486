import { get } from 'lodash'

import * as actions from "../Constants/actionTypes";

const initState = {
    [actions.AT_SHOW_MY_PROJECT]: false,
};

const myReducer = (state = initState, {type, payload}) => {
    switch (type) {
        // show my project
        case actions.AT_SHOW_MY_PROJECT: {
            return {
                ...state,
                [actions.AT_SHOW_MY_PROJECT]: payload,
            };
        }
        default:
            return state;
    }
};

export default myReducer;
