import * as urlApis from "./constantsApi";
import axiosService from "../Utils/axiosServiceUtils";

// my project
export const getMyProjectApi = (query) => {
    return axiosService.getWithToken(`${urlApis.API_PROJECT}?${query}`);
};

export const selectDefaultMyProjectApi = (data) => {
    return axiosService.postWithToken(`${urlApis.API_PROJECT}select-default/`, data);
};

export const createProjectApi = (data) => {
    return axiosService.postWithToken(`${urlApis.API_PROJECT}`, data);
};

export const updateProjectApi = (id, data) => {
    return axiosService.putWithToken(`${urlApis.API_PROJECT}${id}/`, data);
};

export const getDetailProjectApi = (id, query='') => {
    return axiosService.getWithToken(`${urlApis.API_PROJECT}${id}/?${query}`);
};

export const getReportProjectApi = (id, query='') => {
    return axiosService.getWithToken(`${urlApis.API_PROJECT}report/${id}/?${query}`);
};

export const setAdminProjectApi = (data) => {
    return axiosService.postWithToken(`${urlApis.API_PROJECT}set-admin/`, data);
};

export const searchUserApi = (query) => {
    return axiosService.getWithToken(`${urlApis.API_PROJECT}search-user/?${query}`);
};

export const addUserProjectApi = (data) => {
    return axiosService.postWithToken(`${urlApis.API_PROJECT}add-user/`, data);
};

export const removeUserProjectApi = (data) => {
    return axiosService.postWithToken(`${urlApis.API_PROJECT}remove-user/`, data);
};

export const triggerRunProjectApi = (data) => {
    return axiosService.postWithToken(`${urlApis.API_PROJECT}trigger-run/`, data);
};

export const getKeywordsSuggestApi = (params) => {
    // { "domain": "google.com", "location": "Australia", "limit": 10, "sort_by": "search_volume" }
    const query_params = new URLSearchParams(params).toString();
    return axiosService.getWithToken(`${urlApis.API_PROJECT}keywords-suggest/?${query_params}`);
};

export const addSendInviteApi = (data) => {
    return axiosService.postWithToken(`${urlApis.API_PROJECT}invite-user/`, data);
};