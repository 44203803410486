/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {get,round,find} from 'lodash'
import {Link} from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next';

import {KTSVG, toAbsoluteUrl} from '_metronic/helpers'
import {PROJECT_URL} from "Constants/routerConstants";
import tableHelp from 'Constants/tableHelp';

const KeywordTracking = ({className, ...props}) => {

  const keyword_tracking_key = props.pages.keyword_tracking;
  const projectId = get(props, 'projectId')
  const key_param = keyword_tracking_key > 0 ? `/${keyword_tracking_key}` : ''

  const handleLearnMore = () => {
    // props.setPages({...props.pages, keyword_tracking: keyword_tracking_key + 1})
    const new_index = keyword_tracking_key - 1
    props.setPages({...props.pages, keyword_tracking: new_index >= 0 ? new_index : keyword_tracking_key})
  }
  
  const keyword_tracking = get(props, `keyword_tracking[${keyword_tracking_key}]`);
  const keyword_trackings = get(keyword_tracking, 'results', []).filter(v => !!v.rank).sort((a,b) => a.rank - b.rank).slice(0, 3); // sort ASC - limit 3

  const pre_keyword_tracking = get(props, `keyword_tracking[${keyword_tracking_key > 0 ? keyword_tracking_key - 1 : keyword_tracking_key}]`);

  const columns = [
    {
      text: tableHelp.Keyword_Tracking.Keyword,
      dataField: 'query',
      sort: true,
    },
    {
      text: tableHelp.Keyword_Tracking.Rank,
      dataField: 'rank',
      sort: true,
      formatter: (cell, row, rowIndex) => row.rank || '-'
    },
    {
      text: tableHelp.Keyword_Tracking.Change,
      // dataField: 'change',
      sort: true,
      formatter: (cell, row, rowIndex) => {
        const pre_keyword_tracking__results = get(pre_keyword_tracking, 'results', [])
        // const pre_rank = pre_keyword_tracking__results[rowIndex].rank;
        const pre_rank = get(find(pre_keyword_tracking__results, ['keyword', row.keyword]), 'rank', row.rank);
  
        const change = round(row.rank - pre_rank, 2);
        return (
          <div className="text-muted">
            {change > 0 && <KTSVG path='/media/icons/duotune/arrows/arr081.svg' className='svg-icon-5 svg-icon-success ms-1'/>}
            {change < 0 && <KTSVG path='/media/icons/duotune/arrows/arr082.svg' className='svg-icon-5 svg-icon-danger ms-1'/>}
            <span className={change >= 0 ? 'text-primary' : 'text-danger'}>{change}</span>
          </div>
        )
      },
    },
    {
      text: tableHelp.Keyword_Tracking.Avg,
      dataField: 'avg',
      sort: false,
      formatter: (cell, row, rowIndex) => row.rank || '-'
    },
    {
      text: tableHelp.Keyword_Tracking.Best,
      dataField: 'best',
      sort: false,
      formatter: (cell, row, rowIndex) => row.rank || '-'
    },
    {
      text: tableHelp.Keyword_Tracking.Search,
      dataField: 'search_volume',
      sort: true,
      formatter: (cell, row, rowIndex) => row.search_volume || '-'
    },
    {
      text: tableHelp.Keyword_Tracking.EV,
      dataField: 'estimated_traffic',
      sort: true,
      formatter: (cell, row, rowIndex) => round(row.estimated_traffic, 2)
    },
  ];

  const defaultSorted = [{
    dataField: 'rank', // if dataField is not match to any column you defined, it will be ignored.
    order: 'asc' // desc or asc
  }];

  return (
    <div className={`card ${className} keyword-tracking`}>
      {/* begin::Header */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Keyword Tracking</span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>More than 400 new authors</span> */}
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 d-flex flex-column justify-content-between pt-0 pb-0'>
        <div>
          <div className="keyword-tracking__table text-muted">
            <BootstrapTable
              keyField="keyword"
              data={ keyword_trackings }
              columns={ columns }
              noDataIndication="Table is Empty"
              classes="table align-middle gy-3"
              headerWrapperClasses="fw-bolder text-gray-700"
              bodyClasses="text-muted"
              defaultSorted={defaultSorted}
            />
            {/* <table className='table align-middle gy-3'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='p-0'>Keyword</th>
                  <th className='p-0'>Rank</th>
                  <th className='p-0'>Change</th>
                  <th className='p-0'>Avg.</th>
                  <th className='p-0'>Best</th>
                  <th className='p-0'>Search</th>
                  <th className='p-0'>EV</th>
                </tr>
              </thead>
              <tbody>
                {
                  get(keyword_tracking, 'results', []).map((item, key) => {
                    const pre_keyword_tracking__results = get(pre_keyword_tracking, 'results', [])
                    const pre_rank = pre_keyword_tracking__results[key].rank;

                    const change = round(item.rank - pre_rank, 2);
                    return key < 7 && (
                      <tr key={key}>
                        <td className="text-muted">{item.keyword}</td>
                        <td className="text-muted">{item.rank}</td>
                        <td className="text-muted">
                          {
                            change >= 0 ?
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr081.svg'
                              className='svg-icon-5 svg-icon-success ms-1'
                            /> : 
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr082.svg'
                              className='svg-icon-5 svg-icon-danger ms-1'
                            />
                          }
                          <span className={change >= 0 ? 'text-primary' : 'text-danger'}>{change}</span>
                        </td>
                        <td className="text-muted">{item.rank}</td>
                        <td className="text-muted">{item.rank}</td>
                        <td className="text-muted">{item.search_volume}</td>
                        <td className="text-muted">{item.estimated_traffic}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table> */}
          </div>

          {/* <div className="text-end pt-3">
            <button className="btn btn-primary btn-sm me-3">
              <KTSVG
                path='/media/icons/duotune/general/gen047.svg'
                className='svg-icon-5 svg-icon-success ms-1'
              />
              Tags
            </button>
            <button className="btn btn-primary btn-sm">
              <KTSVG
                path='/media/icons/duotune/arrows/arr044.svg'
                className='svg-icon-5 svg-icon-success ms-1'
              />
              Export
            </button>
          </div> */}
        </div>

        <div className="pt-8 pb-8">
          {/* <button 
            className={`btn btn-primary w-100 py-3`} 
            onClick={handleLearnMore} 
            // disabled={keyword_tracking_key >= (props.keyword_tracking.length - 1)}
            disabled={keyword_tracking_key === 0}
          >Learn more</button> */}
          <Link className={`btn btn-primary w-100 py-3 ${get(keyword_tracking, 'results', []).length === 0 ? 'pe-none' : ''}`} to={`${PROJECT_URL}/report/${projectId}/keyword-tracking${key_param}`}>Learn more</Link>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {KeywordTracking}
