import React from "react";
import { get } from "lodash";
import { ErrorMessage } from "formik";

const Select = ({
  id,
  options,
  label,
  type,
  placeholder,
  required,
  field,
  form: { touched, errors, setFieldValue, isSubmitting },
  showEmpty = true,
  ...props
}) => {
  return (
    <>
      <select custom
        {...props}
        id={id}
        name={field.name}
        placeholder={placeholder}
        disabled={isSubmitting}
        value={field.value} 
        // required={required || false}
        onChange={evt => {
          setFieldValue(field.name, evt.target.value);
        }}
        className={`form-select ${props.className} ${get(touched, field.name) && get(errors, field.name) && 'is-invalid'}`}
      >
        {showEmpty && <option value="">-- Please Select --</option>}
        {
          options && options.length > 0 && options.map((v, k) => (
            <option key={k} value={v.value} disabled={v.disabled}>{v.label}</option>
          ))
        }
      </select>
      <ErrorMessage component="div" name={field.name} className="invalid-feedback" />
    </>
  )
}

export default Select;