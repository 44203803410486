import React, { useEffect } from "react";
import { get } from "lodash";
import { Route, Redirect } from "react-router";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { hasPermissionWithRouter, isMember } from "./../Utils/roleAndPermUtils";
import { getCreatePageViewApi } from "Apis/reportsApi";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AdminRoute = ({
  component: Component,
  layout: Layout,
  isAuthenticated,
  profile,
  ...rest
}) => {
  const location = useLocation();
	// const groups = get(profile, 'groups', []);
	// const memberCheck = isMember(groups);

	// useEffect(() => {
	// 	memberCheck && handleCreatePageView()
	// }, [location]);

	// const handleCreatePageView = async () => {
	// 	await getCreatePageViewApi({path_name: get(location, 'pathname')});
	// }

  const useStripe = get(rest, 'useStripe', false);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          // return (hasBelongGroup(profile) && hasBelongPerm(profile)) ? (
          return hasPermissionWithRouter(rest.allows, profile) ? (
            <Layout
              routeName={rest.name}
              pageClass={rest.pageClass || ''}
              layoutConfig={rest.layoutConfig || {}}
            >
              {
                useStripe ?
                <Elements stripe={stripePromise}>
                  <Component {...props} />
                </Elements> :
                <Component {...props} />
              }
            </Layout>
          ) : (
            <Redirect
              to={{
                pathname: "/403",
                state: { referer: props.location },
              }}
            />
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { referer: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authentication.isAuthenticated,
  profile: state.user.profile,
});

AdminRoute.prototype = {
  isAuthenticated: PropTypes.bool,
  profile: PropTypes.object,
};

export default connect(mapStateToProps, null)(AdminRoute);
