/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
// import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {connect} from 'react-redux'
import {get, includes} from 'lodash'
import {compose} from 'redux'
import moment from 'moment'
import {Link} from 'react-router-dom'

import ProjectView from '../Projects/components/view-report'
// import ProjectForm from '../Projects/components/form';
import {GROUPS} from 'Constants'
import {Spinner} from 'Components'
import {AlertError, AlertSuccess, AlertWarning} from 'Utils/alertUtils'
import {triggerRunProjectApi} from 'Apis/projectApi'
import {STATUS_CODE} from 'Constants/statusCodeApi'
import {getUserProfileRequestAction} from 'Store/Actions/userAction'
import {PROJECT__ADD_URL} from 'Constants/routerConstants'

import './index.scss'

const DashboardPage = (props) => {
  const select_project = get(props, 'profile.select_project')
  const select_project_id = get(select_project, 'project_obj.id')
  const user_groups = get(props, 'profile.groups', [])
  const groups = user_groups.map((v) => v.name)
  const [triggerRunLoading, setTriggerRunLoading] = useState(false)
  const project_status = get(select_project, 'project_obj.project_status')
  const run_status = get(project_status, 'run_status')
  const project_count = get(props, 'profile.project_count', 0)


  const handleTriggerRun = () => {
    if (run_status === 'pending') {
      const message = get(project_status, 'message', 'Previous run pending.')
      AlertWarning(message)
      return false
    }
    setTriggerRunLoading(true)
    triggerRunProjectApi({project_id: select_project_id}).then((resp) => {
      setTriggerRunLoading(false)
      if (resp && resp.status === STATUS_CODE.HTTP_200_OK) {
        AlertSuccess('Successfully!')
        props.getUserProfileRequestAction()
      } else {
        AlertError('Server Error')
      }
    })
  }

  if(project_count === 1 && run_status === 'pending') {
    return (
      <div className="alert alert-primary" role="alert">
        Rankster’s AI engine is collecting millions of data points specific to your industry to build your dataset. This process will take approximately one hour.
      </div>
    )
  }

  if (!!select_project_id) {
    return (
      <>
        {includes(groups, GROUPS.TRIGGER_PERMISSION) && (
          <div className={`card mb-5 mb-xl-8`}>
            {/* begin::Body */}
            <div className='card-body'>
              <button
                className='btn btn-primary btn-sm'
                onClick={handleTriggerRun}
                disabled={triggerRunLoading || run_status === 'pending'}
              >
                {run_status === 'pending' ? 'Running' : 'Start Run'}
                {triggerRunLoading && <Spinner className='ml-2' />}
              </button>
            </div>
            {/* end::Body */}
          </div>
        )}
        <ProjectView projectId={select_project_id} setLatestRun={props.setLatestRun} />
      </>
    )
  }

  return (
    <>
      <div className='mb-3 text-muted'>
        Welcome{' '}
        <strong className='text-dark text-capitalize'>{get(props, 'profile.first_name')}</strong>,
      </div>
      {/* <ProjectForm/> */}
      <div className='text-center no-project'>
        <h3 className='pt-5 mt-5 text-muted'>No Projects to display.</h3>
        <div className='mt-5'>
          {project_count === 0 ? (
              <Link className={`btn btn-primary`} to={PROJECT__ADD_URL}>
                Create your first Project
              </Link>
            ) : <div>Click "Choose a project" button to select default display your project</div>
          }
        </div>
      </div>
    </>
  )
}

const DashboardWrapperComponent = (props) => {
  const [latestRun, setLatestRun] = useState('')

  const select_project = get(props, 'profile.select_project')
  // const intl = useIntl()
  const pageTitle = (
    <div className='py-2'>
      {select_project ? (
        <>
          <p>{`${get(select_project, 'project_obj.name', '-')} Dashboard`}</p>
          <div className='fs-6 fw-normal'>
            Date last run:{' '}
            <strong>{latestRun ? moment(latestRun).format('DD MMM YYYY') : '-'}</strong>
          </div>
          <div className='fs-6 fw-normal'>
            Server Location:{' '}
            <strong>{get(select_project, 'project_obj.location_obj.name', '-')}</strong>
          </div>
          <div className='fs-6 fw-normal'>
            Device:{' '}
            <strong>
              {get(select_project, 'project_obj.device', 1) === 1 ? 'Mobile' : 'Desktop'}
            </strong>
          </div>
        </>
      ) : (
        <div>Dashboard</div>
      )}
    </div>
  )
  return (
    <>
      <PageTitle breadcrumbs={[]} children={pageTitle} />
      <DashboardPage {...props} setLatestRun={setLatestRun} />
    </>
  )
}

const mapStateToProps = (state) => ({
  // state: state,
  isAuthenticated: state.authentication.isAuthenticated,
  //
  profile: state.user.profile,
})

const mapDispatchToProps = (dispatch) => ({
  getUserProfileRequestAction: () => {
    dispatch(getUserProfileRequestAction())
  },
})

const withCompose = compose(connect(mapStateToProps, mapDispatchToProps))

const DashboardWrapper = withCompose(DashboardWrapperComponent)

export {DashboardWrapper}
