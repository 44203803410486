/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {get, filter} from 'lodash'
import {Link} from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

// import {KTSVG, toAbsoluteUrl} from '_metronic/helpers'
import {PROJECT_URL} from "Constants/routerConstants";
import tableHelp from 'Constants/tableHelp';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

const ContentGaps = ({className, showDownloadCsv = false,...props}) => {
  const projectId = get(props, 'projectId')
  const content_gaps_index = get(props, 'pages.content_gaps', 0);

  const content_gaps = get(props, `content_gaps[${content_gaps_index}]`);

  const key_param = content_gaps_index > 0 ? `/${content_gaps_index}` : ''

  const columns = [
    {
      text: tableHelp.Content_Gaps.Keyword,
      csvText: 'Keyword',
      dataField: 'query',
      sort: true,
    },
    {
      text: tableHelp.Content_Gaps.Avg_CPC,
      csvText: 'Avg CPC',
      dataField: 'avg_cpc',
      sort: true,
      formatter: (cell, row, rowIndex) => row.avg_cpc || '-'
    },
    {
      text: tableHelp.Content_Gaps.Competition_Indexed,
      csvText: 'Competition Indexed',
      dataField: 'competition_indexed',
      sort: true,
      formatter: (cell, row, rowIndex) => row.competition_indexed || '-'
    },
    {
      text: tableHelp.Content_Gaps.Search_Volume,
      csvText: 'Search Volume',
      dataField: 'search_volume',
      sort: true,
      formatter: (cell, row, rowIndex) => row.search_volume || '-'
    },
  ];

  const pageSize = get(props, 'pageSize')
  const isHome = get(props, 'isHome', true)

  const paginationOptions = {
    // pageStartIndex: 0,
    sizePerPage: pageSize || 5,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  };


  // filter data
  let content_gaps_datas = get(content_gaps, 'results', []).sort((a,b) => (b.search_volume || 0) - (a.search_volume || 0));

  const dataFilters = get(props, 'filters', false);
  if (!!dataFilters) {
    content_gaps_datas = filter(content_gaps_datas, dataFilters)
  }

  if (!pageSize) {
    content_gaps_datas = get(content_gaps, 'results', []).slice(0, 5)
  }

  const defaultSorted = [{
    dataField: 'search_volume', // if dataField is not match to any column you defined, it will be ignored.
    order: 'desc' // desc or asc
  }];

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div className="pb-3 text-end">
        <button className="btn btn-primary" onClick={ handleClick }>Download CSV</button>
      </div>
    );
  };

  return (
    <div className={`card ${className} content-gap`}>
      {/* begin::Header */}
      {
        isHome &&
        <div className='card-header border-0 py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Content Gaps</span>
            {/* <span className='text-muted mt-1 fw-bold fs-7'>More than 400 new authors</span> */}
          </h3>
        </div>
      }
      {/* end::Header */}
      {/* begin::Body */}
      <div className={`card-body py-3 d-flex flex-column justify-content-between pt-0 pb-0 ${!isHome ? 'p-0' : ''}`}>
        <div>
          <div className="content-gap__table">
          <ToolkitProvider
            keyField="query"
            data={content_gaps_datas}
            columns={ columns }
            noDataIndication="Table is Empty"
            pagination={ paginationFactory(paginationOptions) }
            exportCSV={{
              fileName: 'content_gaps.csv',
              separator: ',',
              ignoreHeader: false,
              noAutoBOM: false
            }}
          >
            {
              props => (
                <div>
                  {showDownloadCsv && <MyExportCSV { ...props.csvProps } />}
                  <BootstrapTable 
                    { ...props.baseProps } 
                    classes="table align-middle gy-3"
                    headerWrapperClasses="fw-bolder text-gray-700"
                    bodyClasses="text-muted"
                    defaultSorted={ defaultSorted }
                  />
                </div>
              )
            }
          </ToolkitProvider>
          {/* <BootstrapTable
            keyField="keyword"
            data={content_gaps_datas}
            columns={ columns }
            defaultSorted={ defaultSorted }
            noDataIndication="Table is Empty"
            classes="table align-middle gy-3"
            headerWrapperClasses="fw-bolder text-gray-700"
            bodyClasses="text-muted"
            pagination={ paginationFactory(paginationOptions) }
          /> */}
            {/* <table className='table align-middle gy-3'>
              <thead>
                <tr className='fw-bolder text-muted'>
                  <th className='p-0'>Keyword</th>
                  <th className='p-0'>Avg CPC</th>
                  <th className='p-0'>Competition Indexed</th>
                  <th className='p-0'>Search Volume</th>
                </tr>
              </thead>
              <tbody>
                {
                  get(content_gaps, 'results', []).map((item, key) => (
                    <tr key={key}>
                      <td className="text-muted">{item.keyword}</td>
                      <td className="text-muted">{item.avg_cpc}</td>
                      <td className="text-muted">{item.competition_indexed}</td>
                      <td className="text-muted">{item.search_volume}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table> */}
          </div>
          {/* <div className="text-end pt-3">
            <button className="btn btn-primary btn-sm me-3">
              <KTSVG
                path='/media/icons/duotune/general/gen047.svg'
                className='svg-icon-5 svg-icon-success ms-1'
              />
              Tags
            </button>
            <button className="btn btn-primary btn-sm">
              <KTSVG
                path='/media/icons/duotune/arrows/arr044.svg'
                className='svg-icon-5 svg-icon-success ms-1'
              />
              Export
            </button>
          </div> */}
        </div>

        {
          isHome && <div className="pt-8 pb-8">
            {/* <button 
              className={`btn btn-primary w-100 py-3`} 
              onClick={handleLearnMore} 
              disabled={props.pages.content_gaps >= (props.content_gaps.length - 1)}
            >Learn more</button> */}
            <Link className={`btn btn-primary w-100 py-3 ${get(content_gaps, 'results', []).length === 0 ? 'pe-none' : ''}`} to={`${PROJECT_URL}/report/${projectId}/content-gaps${key_param}`}>Learn more</Link>
          </div>
        }
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ContentGaps}
