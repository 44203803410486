import React from "react";
import { get, omit } from "lodash";
import { ErrorMessage } from "formik";

import { slugify } from "../Utils/globalUtils"

let timer = 0;

const Input = ({
  id,
  label,
  type,
  placeholder,
  required,
  field,
  form: { touched, errors, setFieldValue, submitForm, isSubmitting },
  ...props
}) => {
  return (
    <>
      <input
        {...omit(props, ['hasSlug', 'submitWhenChange'])}
        type={type}
        id={id}
        placeholder={placeholder}
        name={field.name}
        value={field.value}
        disabled={isSubmitting}
        // required={required || false}
        onChange={evt => {
          setFieldValue(field.name, evt.target[type === 'checkbox' ? 'checked' : 'value']);
          get(props, 'hasSlug') && setFieldValue(get(props, 'hasSlug'), slugify(evt.target.value));

          // check auto submit when form change
          if (get(props, 'submitWhenChange', false)) {
            clearTimeout(timer)
            timer = setTimeout(submitForm, 1000)
          }
        }}
        className={`${props.className} ${get(touched, field.name) && get(errors, field.name) ? `is-invalid`: ''}`}
      />
      <ErrorMessage component="div" name={field.name} className="invalid-feedback" />
    </>
  )
}

export default Input;