import { call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash";

import * as actions from "Store/Constants/actionTypes";
import { STATUS_CODE } from "Constants/statusCodeApi";
import {
    resetPasswordFailureAction,
    resetPasswordSuccessAction,
    activeAccountFailureAction,
    activeAccountSuccessAction,
    resendActivationLinkFailureAction,
    resendActivationLinkSuccessAction,
    forgotPasswordFailureAction,
    forgotPasswordSuccessAction,
    signupFailureAction,
    signupSuccessAction,
    loginFailureAction,
    loginSuccessAction,
} from "Store/Actions/authAction";
import { 
    loginApi, 
    signupApi, 
    forgotPasswordApi, 
    resendActivationLinkApi, 
    activeAccountApi,
    resetPasswordApi 
} from "Apis/authApi";  
import { getUserProfileRequestAction } from "Store/Actions/userAction";
import { loginRequestAction } from "Store/Actions/authAction";

export default function* authSaga() {    
    yield takeLatest(actions.AT_AUTH_LOGIN_REQUEST, loginRequestSaga);
    yield takeLatest(actions.AT_AUTH_SIGNUP_REQUEST, signupRequestSaga);
    yield takeLatest(actions.AT_AUTH_FORGOT_PASSWORD_REQUEST, forgotPasswordRequestSaga);
    yield takeLatest(actions.AT_AUTH_RESEND_LINK_ACTIVATION_REQUEST, resendActivationLinkRequestSaga);  
    yield takeLatest(actions.AT_AUTH_ACTIVE_ACCOUNT_REQUEST, activeAccountRequestSaga);
    yield takeLatest(actions.AT_AUTH_RESET_PASSWORD_REQUEST, resetPasswordRequestSaga);  
}

function* loginRequestSaga({ payload }) {
    const { email, password } = payload.data;
    const resp = yield call(loginApi, {
        username: email,
        password,
    });
    const { status, data } = resp;
    if (status === STATUS_CODE.HTTP_200_OK) {
        yield put(loginSuccessAction(data));
        yield put(getUserProfileRequestAction());
    } else if (status === STATUS_CODE.HTTP_401_UNAUTHORIZED) {
        const error = get(data, 'detail', "Account info is incorrect or not active yet!");
        yield put(loginFailureAction({ error }));
    } else {
        const error = get(data, 'detail', "Can not login at this time. Please try again!");
        yield put(loginFailureAction({ error }));
    }
    payload.callback && payload.callback(resp);
}

function* signupRequestSaga({ payload }) { 
    // const { email, password } = payload.data;
    const resp = yield call(signupApi, payload.data);
    const { status, data } = resp; 
    if (status === STATUS_CODE.HTTP_201_CREATED) {
        const { message } = data;
        yield put(signupSuccessAction(message));
        // yield put(loginRequestAction({email, password}));
    } else if (status === STATUS_CODE.HTTP_ME_450_EMAIL_EXIST) { 
        const error = "Email exist!";
        yield put(signupFailureAction({ error }));
    } else {
        const error = "Can not signup at this time. Please try again!";
        yield put(signupFailureAction({ error }));
    }
    payload.callback && payload.callback(resp);
}

function* forgotPasswordRequestSaga({ payload }) { 
    const { email } = payload.data;
    const resp = yield call(forgotPasswordApi, {
        email,
    });
    const { status, data } = resp; 
    if (status === STATUS_CODE.HTTP_200_OK) {
        const { message } = data;
        yield put(forgotPasswordSuccessAction(message));
    } else if (status === STATUS_CODE.HTTP_ME_451_EMAIL_DOES_NOT_EXIST) { 
        const error = "Email does not exist!";
        yield put(forgotPasswordFailureAction({ error }));
    } else if (status === STATUS_CODE.HTTP_ME_452_ACCOUNT_IS_NOT_ACTIVATED) { 
        const error = "The account is not activated!";
        yield put(forgotPasswordFailureAction({ error }));
    } else {
        const error = "Sorry, the password reset feature is unavailable at this time. Please try again later.";
        yield put(forgotPasswordFailureAction({ error }));
    }
}

function* resendActivationLinkRequestSaga({ payload }) { 
    const { email } = payload.data;
    const resp = yield call(resendActivationLinkApi, {
        email,
    });
    const { status, data } = resp; 
    if (status === STATUS_CODE.HTTP_200_OK) {
        const { message } = data;
        yield put(resendActivationLinkSuccessAction(message));
    } else if (status === STATUS_CODE.HTTP_ME_451_EMAIL_DOES_NOT_EXIST) { 
        const error = "Email does not exist!";
        yield put(resendActivationLinkFailureAction({ error }));
    } else if (status === STATUS_CODE.HTTP_ME_453_ACCOUNT_IS_ACTIVATED) { 
        const error = "The account is activated already!";
        yield put(resendActivationLinkFailureAction({ error }));
    } else {
        const error = "Can not resend activation link at this time. Please try again!";
        yield put(resendActivationLinkFailureAction({ error }));
    }
}

function* activeAccountRequestSaga({ payload }) { 
    const { email, access_token } = payload.data;
    const resp = yield call(activeAccountApi, {
        email,
        access_token,
    });
    const { status, data } = resp
    payload.callback && payload.callback(resp);
    if (status === STATUS_CODE.HTTP_200_OK) {
        const { message } = data;
        yield put(activeAccountSuccessAction(message));
        yield put(loginSuccessAction({access: access_token}));
    } else if (status === STATUS_CODE.HTTP_ME_451_EMAIL_DOES_NOT_EXIST) { 
        const error = "Email does not exist!";
        yield put(activeAccountFailureAction({ error }));
    } else if (status === STATUS_CODE.HTTP_ME_453_ACCOUNT_IS_ACTIVATED) { 
        const error = "The account is activated already!";
        yield put(activeAccountFailureAction({ error }));
    }else if (status === STATUS_CODE.HTTP_ME_455_TOKEN_INCORRECT_OR_EXPIRED) { 
        const error = "Token is incorrect or expired!";
        yield put(activeAccountFailureAction({ error }));
    } else {
        const error = "Can not active account at this time. Please try again!";
        yield put(activeAccountFailureAction({ error }));
    }
}

function* resetPasswordRequestSaga({ payload }) { 
    const { access_token, new_password } = payload.data;
    const resp = yield call(resetPasswordApi, {
        password: new_password,
        access_token,
    });
    const { status, data } = resp; 
    if (status === STATUS_CODE.HTTP_200_OK) {
        const { message, email } = data;
        yield put(resetPasswordSuccessAction(message));
        yield put(loginRequestAction({email, password: new_password}));
    }else if (status === STATUS_CODE.HTTP_ME_455_TOKEN_INCORRECT_OR_EXPIRED) { 
        const error = "Token is incorrect or expired or the email does not exist!";
        yield put(resetPasswordFailureAction({ error }));
    } else {
        const error = "Can not reset password at this time. Please try again!";
        yield put(resetPasswordFailureAction({ error }));
    }
}