/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { Link } from 'react-router-dom'
import { get, forEach, chain } from 'lodash'
import { getCSS, getCSSVariableValue } from '_metronic/assets/ts/_utils'
import { PROJECT_URL } from "Constants/routerConstants";

// use https://unpkg.com/lightweight-charts@4.1.1/dist/lightweight-charts.standalone.production.js to load lightweight-charts
// import { createChart } from 'lightweight-charts';


const ExpectedImprovements = ({ className, domainchart, projectDetail, ranking_gain_data = [], ...props }) => {

    // console.log('props!!!', props.ranking_gain_data)

    // const ranking_gain_data = props.ranking_gain_data?.[0]?.results || [];
    // read ranking_gain_data from /Users/yovi/Downloads/49.json for dev
    // let ranking_gain_data = require('49.json').ranking_gain_data[0].results;

    // Data Arrays for Chart Series
    let optimisedData = [];
    let criticalData = [];
    let dotPlotData = [];

    const black_color = "#000";
    const orange_color = "#ffa500";

    // filter ranking_gain_data for int(sim) < 5. Coerce sim to number because it's char
    // ranking_gain_data = ranking_gain_data.filter(item => parseInt(item.sim) < 5);

    ranking_gain_data.forEach(item => {
        // For line charts
        if (item.name === "Optimised+Critical Issues") {
            optimisedData.push({ x: item.rank, y: item.optimised_line })
        } else if (item.name === "Critical Issues") {
            criticalData.push({ x: item.rank, y: item.critical_line })
        }
        // For dot plot
        let color = item.name === "Optimised+Critical Issues" ? black_color : orange_color; // Replace 'color1' and 'color2' with actual color values
        dotPlotData.push({ x: item.rank, y: item.value, fillColor: color });
    });


    // console.log('optimisedData!!!', optimisedData)
    // console.log('criticalData!!!', criticalData)
    // console.log('dotPlotData!!!', dotPlotData)

// keep only n % of the data
    // const n = 0.05;
    // const optimisedDataLength = optimisedData.length;
    // const criticalDataLength = criticalData.length;
    // const dotPlotDataLength = dotPlotData.length;
    // optimisedData = optimisedData.filter((item, index) => index % Math.round(optimisedDataLength / (optimisedDataLength * n)) === 0);
    // criticalData = criticalData.filter((item, index) => index % Math.round(criticalDataLength / (criticalDataLength * n)) === 0);
    // dotPlotData = dotPlotData.filter((item, index) => index % Math.round(dotPlotDataLength / (dotPlotDataLength * n)) === 0);



    const series = [
        // {
        //     name: 'Search Positions Gained',
        //     data: ranks.map((rank, index) => [rank, values[index]]),
        //     type: 'scatter',
        // },
        // {
        //     name: 'Optimised Search Positions Gained',
        //     data: ranks.map((rank, index) => [rank, gainOptimised[index]]),
        //     type: 'scatter',
        // }
        {
            name: 'Optimised+Critical Issues',
            type: 'line',
            data: optimisedData.splice(0, 30),
            color: black_color
        },
        {
            name: 'Critical Issues',
            type: 'line',
            data: criticalData.splice(0, 30),
            color: orange_color
        },
        {
            name: 'Value',
            type: 'scatter',
            data: dotPlotData
        }

    ];

    // Basic chart options
    const options = {
        chart: {
            type: 'line'
        },
        series: series,
        xaxis: {
            type: 'numeric',
            title: {
                text: 'Number of Rankster Issues Addressed'
            }
        },
        yaxis: [
            {
                title: {
                    text: 'Search Positions Gained'
                }
            },
            {
                opposite: true,
                title: {
                    text: 'Estimated % Improvement in Search Rank'
                },
                labels: {
                    formatter: function (value) {
                        return (value / 51.1 * 100).toFixed(2) + '%';
                    }
                }
            }
        ],
        markers: {
            size: 5
        },
        tooltip: {
            shared: false,
            intersect: true
        },
        legend: {
            position: 'top',
            formatter: function (seriesName, opts) {
                //     // List the names of the series you want to show in the legend
                //     const showInLegend = ['Search Positions Gained', 'Optimised Search Positions Gained'];
                const hideInLegend = ['Value']
                if (hideInLegend.includes(seriesName)) {
                    return ''; // Don't show in legend
                }
                return seriesName; // Show in legend
            }
        },
        title: {
            text: 'Rankster Rank Gain Improvements',
            align: 'center'
        },
        stroke: {
          curve: "smooth"
        }

    };
    const chartRef = useRef(null)


    useEffect(() => {
        if (!chartRef.current) {
            return
        }

        const chart = new ApexCharts(
            chartRef.current,
            options
        )
        if (chart) {
            chart.render()
        }

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef])



    return <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '350px' }}></div>


}

export { ExpectedImprovements }