import React from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

export function TriggerTooltip({title, tooltip, className = '', showIcon = true}) {

  const renderTooltip = (props) => (
    <Tooltip {...props}>{tooltip}</Tooltip>
  );

  return (
    <OverlayTrigger
      placement="auto-start"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <span className={className}>{title} {showIcon && <i className="bi bi-question-circle"></i>}</span>
    </OverlayTrigger>
  );
}

const tableHelp = {
  Keyword_Tracking: {
    Keyword: <TriggerTooltip title={'Keyword'} tooltip={`Keyword`} />,
    Rank: <TriggerTooltip title={'Rank'} tooltip={`Rank`} />,
    Change: <TriggerTooltip title={'Change'} tooltip={`Change`} />,
    Avg: <TriggerTooltip title={'Avg.'} tooltip={`Avg`} />,
    Best: <TriggerTooltip title={'Best'} tooltip={`Best`} />,
    Search: <TriggerTooltip title={'Search'} tooltip={`Search`} />,
    EV: <TriggerTooltip title={'EV'} tooltip={`EV`} />,
    BestRankingLink: <TriggerTooltip title={'Best Ranking Link'} tooltip={`Best Ranking Link`} />,
    Volume: <TriggerTooltip title={'Volume'} tooltip={`Volume`} />,
    Keyword_Difficulty: <TriggerTooltip title={'Keyword Difficulty %'} tooltip={`Keyword Difficulty %`} />,
  },
  Content_Gaps: {
    Keyword: <TriggerTooltip title={'Keyword'} tooltip={`Keyword`} />,
    Avg_CPC: <TriggerTooltip title={'Avg CPC'} tooltip={`Avg CPC`} />,
    Competition_Indexed: <TriggerTooltip title={'Competition Indexed'} tooltip={`Competition Indexed`} />,
    Search_Volume: <TriggerTooltip title={'Search Volume'} tooltip={`Search Volume`} />,
  },
  Competition_Map: {
    Domain: <TriggerTooltip title={'Domain'} tooltip={`Domain`} />,
    Market_Share: <TriggerTooltip title={'Market Share Impressions'} tooltip={`Market Share Impressions`} />,
    Keyword_Penetration: <TriggerTooltip title={'Search Term Market Penetration'} tooltip={`Search Term Market Penetration`} />,
    Average_Search_Position: <TriggerTooltip title={'Average Search Position'} tooltip={`Average Search Position`} />,
    Keyword_Penetration_Difference: <TriggerTooltip title={'Search Term Market Penetration Difference'} tooltip={`Search Term Market Penetration Difference`} />,
    Market_Share_Impressions_Difference: <TriggerTooltip title={'Market Share Impressions Difference'} tooltip={`Market Share Impressions Difference`} />,
    domain_search_volume: <TriggerTooltip title={'Domain Search Volume'} tooltip={`Domain Search Volume`} />,
    num_ranking_keywords: <TriggerTooltip title={'Ranking Keywords'} tooltip={`Ranking Keywords`} />,
  },
}

export default tableHelp