// export const URL_SITE = "http://127.0.0.1:5001"; 
export const URL_SITE = process.env.REACT_APP_API_URL || "http://127.0.0.1:8000/api/";

// User Profile
export const API_CHANGE_PASSWORD_POST = URL_SITE + "account/change-password/";
export const API_UPDATE_PROFILE_POST = URL_SITE + "account/update-user-profile/";
export const API_USER_PROFILE_GET = URL_SITE + "account/get-user-profile/";
export const API_USER_DASHBOARD_GET = URL_SITE + "get-user-dashboard/";

// Auth
export const API_RESET_PASSWORD_POST = URL_SITE + "auth/reset-password/";
export const API_ACTIVE_ACCOUNT_POST = URL_SITE + "auth/active-account/";
export const API_RESEND_LINK_ACTIVATION_POST = URL_SITE + "auth/resend-link-active/";
export const API_FORGOT_PASSWORD_POST = URL_SITE + "auth/forgot-password/";
export const API_SIGNUP_POST = URL_SITE + "auth/register/";
export const API_SIGNUP_GET = URL_SITE + "auth/register/get-invite-info/";
export const API_LOGIN_POST = URL_SITE + "auth/login/";

export const API_GET_COUNTRY_LIST = URL_SITE + "get-country-list/";

// payment
// payment api
export const API_PAYMENT = URL_SITE + "payment/";
export const API_PAYMENT__GET_PAYMENT_ITEMS = API_PAYMENT + "get-payment-items/";
export const API_GET_PAYMENT_LOG_LIST = API_PAYMENT + "payment-logs/";
export const API_GET_PRACTICE_MEMBERSHIP_LIST = API_PAYMENT + "get-practice-membership/";
export const API_GET_LESSON_MEMBERSHIP_LIST = API_PAYMENT + "get-lesson-membership/";
export const API_CREATE_PAYMENT_LOG = API_PAYMENT + "payment-logs/";

// stripe
export const API_STRIPE = URL_SITE + "stripe/";
export const API_STRIPE_CREATE_SETUP_INTENT = API_STRIPE + "create-setup-intent/";
export const API_STRIPE_PACKAGE_PURCHASE = API_STRIPE + "purchase/";
export const API_STRIPE_CREATE_SESSION = API_STRIPE + "create-session/";
export const API_STRIPE_DELETE_PAYPEMT_METHOD = API_STRIPE + "detach-payment-method/";
export const API_STRIPE_UPDATE_DEFAULT_PAYPEMT_METHOD = API_STRIPE + "update-default-payment-method/";
export const API_STRIPE_UPDATE_DEFAULT_PAYPEMT_METHOD_BY_SESSION_ID = API_STRIPE + "update-default-payment-method-by-session-id/";

// REPORTS
export const API_REPORTS = URL_SITE + "reports/";
export const API_REPORTS__NUMBER_OF_LESSONS_GIVEN = API_REPORTS + "number-of-lessons-given/";
export const API_REPORTS__REVENUE_BROKEN_DOWN_BY_PRODUCT = API_REPORTS + "revenue-broken-down-by-product/";
export const API_REPORTS__USERS_CANCELLED_SUBSCRIPTION = API_REPORTS + "users-canceled-subscription/";
export const API_REPORTS__PAYMENT_LOGS = API_REPORTS + "payment-logs/";
export const API_REPORTS__AT_RISK_REPORT = API_REPORTS + "at-risk-report/";
export const API_REPORTS__PAGE_VIEW = API_REPORTS + "pageview/";
export const API_REPORTS__DASH_BOARD = API_REPORTS + "dashboard/";
export const API_REPORTS__MEMBERS = API_REPORTS + "members/";
export const API_REPORTS__MEMBERS__TYPES = {
  active: 'active',
  expired: 'expired',
  trial: 'trial',
};
export const API_REPORTS__CREATE_PAGEVIEW = URL_SITE + "pageview/";

// Documentations
export const API_DOCUMENTATIONS_GET = URL_SITE + "recommendations/";

export const API_RECOMENDATION_TO_DO_GET = URL_SITE + "recommendations-to-do/";

export const API_REPORTS__DASH_BOARD__TYPE_URL = {
  coach_performance: 'coach-performance',
  membership_purchases: 'membership-purchases',
  new_lessons: 'new-lessons',
  new_students: 'new-students',
  token_purchases: 'token-purchases',
};

// PROJECT GET
export const API_PROJECT = URL_SITE + "project/";