// user - profile

export const AT_CHANGE_PASSWORD_REQUEST = "AT_CHANGE_PASSWORD_REQUEST";
export const AT_CHANGE_PASSWORD_SUCCESS = "AT_CHANGE_PASSWORD_SUCCESS";
export const AT_CHANGE_PASSWORD_FAILURE = "AT_CHANGE_PASSWORD_FAILURE";

export const AT_UPDATE_PROFILE_REQUEST = "AT_UPDATE_PROFILE_REQUEST";
export const AT_UPDATE_PROFILE_SUCCESS = "AT_UPDATE_PROFILE_SUCCESS";
export const AT_UPDATE_PROFILE_FAILURE = "AT_UPDATE_PROFILE_FAILURE";

export const AT_USER_GET_PROFILE_REQUEST = "AT_USER_GET_PROFILE_REQUEST";
export const AT_USER_GET_PROFILE_SUCCESS = "AT_USER_GET_PROFILE_SUCCESS";
export const AT_USER_GET_PROFILE_FAILURE = "AT_USER_GET_PROFILE_FAILURE";
export const AT_USER_SET_PROFILE_NULL = "AT_USER_SET_PROFILE_NULL";

export const AT_GET_USER_DASHBOARD_REQUEST = "AT_GET_USER_DASHBOARD_REQUEST";
export const AT_GET_USER_DASHBOARD_SUCCESS = "AT_GET_USER_DASHBOARD_SUCCESS";
export const AT_GET_USER_DASHBOARD_FAILURE = "AT_GET_USER_DASHBOARD_FAILURE";

// authencation

export const AT_AUTH_RESET_PASSWORD_REQUEST = "AT_AUTH_RESET_PASSWORD_REQUEST";
export const AT_AUTH_RESET_PASSWORD_SUCCESS = "AT_AUTH_RESET_PASSWORD_SUCCESS";
export const AT_AUTH_RESET_PASSWORD_FAILURE = "AT_AUTH_RESET_PASSWORD_FAILURE";

export const AT_AUTH_ACTIVE_ACCOUNT_REQUEST = "AT_AUTH_ACTIVE_ACCOUNT_REQUEST";
export const AT_AUTH_ACTIVE_ACCOUNT_SUCCESS = "AT_AUTH_ACTIVE_ACCOUNT_SUCCESS";
export const AT_AUTH_ACTIVE_ACCOUNT_FAILURE = "AT_AUTH_ACTIVE_ACCOUNT_FAILURE";

export const AT_AUTH_RESEND_LINK_ACTIVATION_REQUEST = "AT_AUTH_RESEND_LINK_ACTIVATION_REQUEST";
export const AT_AUTH_RESEND_LINK_ACTIVATION_SUCCESS = "AT_AUTH_RESEND_LINK_ACTIVATION_SUCCESS";
export const AT_AUTH_RESEND_LINK_ACTIVATION_FAILURE = "AT_AUTH_RESEND_LINK_ACTIVATION_FAILURE";

export const AT_AUTH_FORGOT_PASSWORD_REQUEST = "AT_AUTH_FORGOT_PASSWORD_REQUEST";
export const AT_AUTH_FORGOT_PASSWORD_SUCCESS = "AT_AUTH_FORGOT_PASSWORD_SUCCESS";
export const AT_AUTH_FORGOT_PASSWORD_FAILURE = "AT_AUTH_FORGOT_PASSWORD_FAILURE";

export const AT_AUTH_SIGNUP_REQUEST = "AT_AUTH_SIGNUP_REQUEST";
export const AT_AUTH_SIGNUP_SUCCESS = "AT_AUTH_SIGNUP_SUCCESS";
export const AT_AUTH_SIGNUP_FAILURE = "AT_AUTH_SIGNUP_FAILURE";

export const AT_AUTH_LOGIN_REQUEST = "AT_AUTH_LOGIN_REQUEST";
export const AT_AUTH_LOGIN_SUCCESS = "AT_AUTH_LOGIN_SUCCESS";
export const AT_AUTH_LOGIN_FAILURE = "AT_AUTH_LOGIN_FAILURE";

export const AT_AUTH_REDIRECT_TO_LOGIN_PAGE = "AT_AUTH_REDIRECT_TO_LOGIN_PAGE";
export const AT_AUTH_LOGOUT = "AT_AUTH_LOGOUT";

export const AT_HELLO = "AT_HELLO";


// =============
// Payment 
// =============
export const AT_GET_PAYMENT_LOG_REQUEST = "AT_GET_PAYMENT_LOG_REQUEST";
export const AT_GET_PAYMENT_LOG_SUCCESS = "AT_GET_PAYMENT_LOG_SUCCESS";
export const AT_GET_PAYMENT_LOG_FAILURE = "AT_GET_PAYMENT_LOG_FAILURE";


// ======
// PROJECT
// ======
export const AT_GET_MY_PROJECT_REQUEST = "AT_GET_MY_PROJECT_REQUEST";
export const AT_GET_MY_PROJECT_SUCCESS = "AT_GET_MY_PROJECT_SUCCESS";
export const AT_GET_MY_PROJECT_FAILURE = "AT_GET_MY_PROJECT_FAILURE";

export const AT_SHOW_MY_PROJECT = "AT_SHOW_MY_PROJECT";