import React, { Suspense } from "react";
import { Provider } from "react-redux";
//

import {MetronicI18nProvider} from '_metronic/i18n/Metronici18n'

import store from "Store/indexStore";

import Loading from 'Components/Loading'
import Main from './Main';

/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
 import '_metronic/assets/sass/style.scss'
 import '_metronic/assets/sass/style.react.scss'

function App() {
    return (
        <MetronicI18nProvider>
            <Suspense fallback={<Loading />}>
                <Provider store={store}>
                    <Main />
                </Provider>
            </Suspense>
        </MetronicI18nProvider>
    );
}

export default App;
