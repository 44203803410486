import React from "react";

const Spinner = () => {
  return (
    <span className='indicator-progress' style={{display: 'initial'}}>
      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    </span>
  )
}

export default Spinner;