import {toast} from 'react-toastify'

const toast_options = {
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored'
}

export const AlertSuccess = (message) => {
  return toast.success(message, toast_options)
}

export const AlertError = (message) => {
  return toast.error(message, toast_options)
}

export const AlertWarning = (message) => {
  return toast.warn(message, toast_options)
}

export const AlertInfo = (message) => {
  return toast.info(message, toast_options)
}
