import { call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash";
import * as actions from "../Constants/actionTypes";
import { STATUS_CODE } from "Constants/statusCodeApi";
import {
    getMyProjectFailureAction,
    getMyProjectSuccessAction,
} from "../Actions/projectAction";
import { getMyProjectApi } from "Apis/projectApi";

export default function* projectSaga() {
    yield takeLatest(actions.AT_GET_MY_PROJECT_REQUEST, getMyProjectRequest);
}

function* getMyProjectRequest({ payload }) {
    const resp = yield call(getMyProjectApi, payload);
    // var { status, data } = resp;
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getMyProjectSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getMyProjectFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getMyProjectFailureAction({ error }));
    }
}
