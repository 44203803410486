/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {get, orderBy} from 'lodash'
// import {KTSVG, toAbsoluteUrl} from '_metronic/helpers'
import {Link} from 'react-router-dom'
import {PROJECT_URL} from "Constants/routerConstants";
import {RECOMMENDATIONS__LINK__GLOBAL} from "Constants/global";

const Recommendations = ({className, ...props}) => {

  const projectId = get(props, 'projectId')
  const recommendations_key = get(props, 'page.recommendations', 0);
  const key_param = recommendations_key > 0 ? `/${recommendations_key}` : '';

  const current_recommendations = get(props, `recommendations[${recommendations_key}].results`, []).filter(v => v.link === RECOMMENDATIONS__LINK__GLOBAL)

  const recommendations = orderBy(current_recommendations, [c => c.value], ['desc']).slice(0,5)

  // console.log(recommendations)

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Recommendations</h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 d-flex flex-column justify-content-between pt-0 pb-0'>
        <ul className="ps-5" style={{listStyle: 'decimal'}}>
          {
            recommendations.map((item, key) => 
              <li key={key}>
                {/* begin::Item */}
                <div className='d-flex align-items-center mb-5'>
                  {/* begin::Description */}
                  <div className='flex-grow-1'>
                    <span className='text-gray-800 text-hover-primary fw-bolder fs-6'>
                      {item.title}
                      <span className='badge badge-light-primary fs-8 fw-bolder ms-3'>{item.seo_feature_type}</span>
                    </span>
                    <span className='text-muted d-block'>
                      {/* {item.description !== '0' ? item.description : ''} */}
                      {/* Image formats like WebP and AVIF often provide better compression than PNG or JPEG, which means faster downloads and less data consumption. <a href="#">Learn more.</a> */}
                    </span>
                  </div>
                  {/* end::Description */}
                </div>
                {/* end:Item */}
              </li>
            )
          }
        </ul>
        {!recommendations.length === 0 && <div className="text-muted text-center">Recommendations is Empty</div>}

        <div className="pb-8">
          {/* <button 
            className={`btn btn-primary w-100 py-3`} 
            disabled={true}
            // onClick={handleLearnMore} 
            // disabled={props.pages.keyword_tracking >= (props.keyword_tracking.length - 1)}
          >Learn more</button> */}
          <Link className={`btn btn-primary w-100 py-3 ${recommendations.length === 0 ? 'pe-none' : ''}`} to={`${PROJECT_URL}/report/${projectId}/recommendations${key_param}`}>Learn more</Link>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {Recommendations}
