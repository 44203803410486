import * as actions from "../Constants/actionTypes";

// get my project
export const getMyProjectFailureAction = (payload) => ({
    type: actions.AT_GET_MY_PROJECT_FAILURE,
    payload,
});

export const getMyProjectSuccessAction = (payload) => ({
    type: actions.AT_GET_MY_PROJECT_SUCCESS,
    payload,
});

export const getMyProjectRequestAction = (payload) => ({
    type: actions.AT_GET_MY_PROJECT_REQUEST,
    payload,
});

export const toggleMyProjectModalAction = (payload) => ({
    type: actions.AT_SHOW_MY_PROJECT,
    payload,
});
