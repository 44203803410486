/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {get, round} from 'lodash'
import {KTSVG} from '_metronic/helpers'
import {getCSSVariableValue} from '_metronic/assets/ts/_utils'
import {KeywordItem} from './KeywordItem'

const Keyword = ({className, ...props}) => {

  const top_ranking_keyword_stats_index = props.pages.top_ranking_keyword_stats;
  const current_top_ranking_keyword_stats = get(props, `top_ranking_keyword_stats[${top_ranking_keyword_stats_index}]`, []);
  const pre_top_ranking_keyword_stats = get(props, `top_ranking_keyword_stats[${top_ranking_keyword_stats_index > 0 ? top_ranking_keyword_stats_index - 1 : top_ranking_keyword_stats_index}]`, []);

  const handleLearnMore = () => {
    const new_index = top_ranking_keyword_stats_index - 1
    props.setPages({...props.pages, top_ranking_keyword_stats: new_index >= 0 ? new_index : top_ranking_keyword_stats_index})
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Top Ranking Keyword Stats</span>
          {/* <span className='text-muted fw-bold fs-7'>Complete your profile setup</span> */}
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body d-flex flex-column justify-content-between pt-0'>
        {/* begin::Row */}
        <div className='row g-5 g-xl-8'>
          {/* begin::Col */}
          <div className='col-xl-6'>
            <div className='fw-bolder text-muted fs-3 mb-2'>Top 3</div>
            <KeywordItem
              className='card-xl-stretch h-100'
              chartColor='primary'
              chartHeight='50px'
              {...props}
              ranking_keywords={get(current_top_ranking_keyword_stats, 'top_3.ranking_keywords', [])}
              lost={get(current_top_ranking_keyword_stats, 'top_3.lost', [])}
              gained={get(current_top_ranking_keyword_stats, 'top_3.gained', [])}
              pre_ranking_keywords={get(pre_top_ranking_keyword_stats, 'top_3.ranking_keywords', [])}
              pre_lost={get(pre_top_ranking_keyword_stats, 'top_3.lost', [])}
              pre_gained={get(pre_top_ranking_keyword_stats, 'top_3.gained', [])}
              keyword_key='top_3'
            />
          </div>
          <div className='col-xl-6'>
            <div className='fw-bolder text-muted fs-3 mb-2'>Top 10</div>
            <KeywordItem
              className='card-xl-stretch h-100'
              chartColor='primary'
              chartHeight='50px'
              {...props}
              ranking_keywords={get(current_top_ranking_keyword_stats, 'top_10.ranking_keywords', [])}
              lost={get(current_top_ranking_keyword_stats, 'top_10.lost', [])}
              gained={get(current_top_ranking_keyword_stats, 'top_10.gained', [])}
              pre_ranking_keywords={get(pre_top_ranking_keyword_stats, 'top_10.ranking_keywords', [])}
              pre_lost={get(pre_top_ranking_keyword_stats, 'top_10.lost', [])}
              pre_gained={get(pre_top_ranking_keyword_stats, 'top_10.gained', [])}
              keyword_key='top_10'
            />
          </div>
          <div className='col-xl-6'>
            <div className='fw-bolder text-muted fs-3 mb-2'>Top 20</div>
            <KeywordItem
              className='card-xl-stretch h-100'
              chartColor='primary'
              chartHeight='50px'
              {...props}
              ranking_keywords={get(current_top_ranking_keyword_stats, 'top_20.ranking_keywords', [])}
              lost={get(current_top_ranking_keyword_stats, 'top_20.lost', [])}
              gained={get(current_top_ranking_keyword_stats, 'top_20.gained', [])}
              pre_ranking_keywords={get(pre_top_ranking_keyword_stats, 'top_20.ranking_keywords', [])}
              pre_lost={get(pre_top_ranking_keyword_stats, 'top_20.lost', [])}
              pre_gained={get(pre_top_ranking_keyword_stats, 'top_20.gained', [])}
              keyword_key='top_20'
            />
          </div>
          <div className='col-xl-6'>
            <div className='fw-bolder text-muted fs-3 mb-2'>Top 100</div>
            <KeywordItem
              className='card-xl-stretch h-100'
              chartColor='primary'
              chartHeight='50px'
              {...props}
              ranking_keywords={get(current_top_ranking_keyword_stats, 'top_100.ranking_keywords', [])}
              lost={get(current_top_ranking_keyword_stats, 'top_100.lost', [])}
              gained={get(current_top_ranking_keyword_stats, 'top_100.gained', [])}
              pre_ranking_keywords={get(pre_top_ranking_keyword_stats, 'top_100.ranking_keywords', [])}
              pre_lost={get(pre_top_ranking_keyword_stats, 'top_100.lost', [])}
              pre_gained={get(pre_top_ranking_keyword_stats, 'top_100.gained', [])}
              keyword_key='top_100'
            />
          </div>
          {/* end::Col */}
        </div>
        {/* end::Row */}

        <div className='pt-5'>
          {/* <p className='text-center fs-7 pb-5 '>
            Semrush rank 
            <span className='text-primary fw-bolder ps-2'>698.3K</span>
            <KTSVG
              path='/media/icons/duotune/arrows/arr066.svg'
              className='svg-icon-5 svg-icon-success ms-1'
            />
          </p> */}

          <button 
            className={`btn btn-primary w-100 py-3`} 
            onClick={handleLearnMore} 
            // disabled={top_ranking_keyword_stats_index >= (props.top_ranking_keyword_stats.length - 1)}
            disabled={top_ranking_keyword_stats_index === 0}
          >Learn more</button>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {Keyword}
