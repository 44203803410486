/** =============== PAYMENT ITEMS TYPE =========== */
export const PAYMENT_ITEMS__MEMBERSHIP_TYPE = 1;
export const PAYMENT_ITEMS__ON_DEMAND_TYPE = 2;
export const PAYMENT_ITEMS__NONE = 0;
export const PAYMENT_ITEMS = {
  [PAYMENT_ITEMS__MEMBERSHIP_TYPE]: "Membership",
  [PAYMENT_ITEMS__ON_DEMAND_TYPE]: "On Demand",
  [PAYMENT_ITEMS__NONE]: "-",
};

export const TOKEN_TYPES__ONLINE_LESSON = "Online Lesson";
export const TOKEN_TYPES__FACE_TIME_LESSON = "Facetime Lesson";
export const TOKEN_TYPES__FREE_LESSON = "Free Lesson";
export const TOKEN_TYPES__COLORS = {
  [TOKEN_TYPES__ONLINE_LESSON]: "#0caad4",
  [TOKEN_TYPES__FACE_TIME_LESSON]: "#04DE92",
  [TOKEN_TYPES__FREE_LESSON]: "#999",
};


export const PAYPAL__REPONSE_STATUS = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
}

/** =============== STRIPE PAYMENT =========== */
export const STRIPE_ADD_CARD_TYPE__SUCCESS = "success";
export const STRIPE_ADD_CARD_TYPE__CANCEL = "cancel";

export const STRIPE_PAYMENT_OBJECT_TYPE = {
  PAYMENT_INTENT: "payment_intent",
  SUBSCRIPTION: "subscription",
};

// https://stripe.com/docs/api/subscriptions/object#subscription_object-status
// https://stripe.com/docs/api/payment_intents/object#payment_intent_object-status
export const STRIPE_PAYMENT_RESPONSE_STATUS = {
  canceled: "canceled",

  // subscription_object-status https://stripe.com/docs/api/subscriptions/object#subscription_object-status
  incomplete: "incomplete",
  incomplete_expired: "incomplete_expired",
  trialing: "trialing",
  active: "active",
  past_due: "past_due",
  unpaid: "unpaid",

  // payment_intent_object-status https://stripe.com/docs/api/payment_intents/object#payment_intent_object-status
  requires_payment_method: "requires_payment_method",
  requires_confirmation: "requires_confirmation",
  requires_action: "requires_action",
  processing: "processing",
  requires_capture: "requires_capture",
  succeeded: "succeeded",
};

export const STRIPE_PAYMENT_SUCCESS_STATUS = [
  STRIPE_PAYMENT_RESPONSE_STATUS.active,
  STRIPE_PAYMENT_RESPONSE_STATUS.trialing,
  STRIPE_PAYMENT_RESPONSE_STATUS.succeeded,
];

export const DELAY_FILTER_TIME = 500;

export const CHART_OPTIONS = {
  tooltips: {
    enabled: true,
  },
  scales: {
    xAxes: [
      // {
      //   gridLines: {
      //     display: false,
      //   },
      // },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          // stepSize: 1,
          callback: (value) => value % 1 === 0 && value,
        },
      },
    ],
  },
};


/** =============== PROJECT =========== */
export const PROJECT__FILTER_TYPES = {
  ALL: 1,
  MY_OWN: 2,
  SHARE_WITH_ME: 3,
  TAGS: 4,
};

/** =============== RECOMMENDATION TO DO =========== */
export const RECOMMENDATION_TO_DO_STATUS = {
  OPEN: 1,
  COMPLETED: 2,
};

export const GROUPS = {
  TRIGGER_PERMISSION: 'TRIGGER_PERMISSION',
  USER_ADMIN: 'USER_ADMIN'
}
