import React from "react";

const Loading = () => {
  return (
    <div className="text-center">
      <span className='indicator-progress' style={{display: 'block'}}>
        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        {' '}Loading...
      </span>
    </div>
  )
}

export default Loading;