import * as actions from "../Constants/actionTypes";
import { AlertError, AlertSuccess } from "./../../Utils/alertUtils";

const initState = {
    isLoading: false,
    profile: null,
};

const myReducer = (state = initState, action) => {
    switch (action.type) {
        // change password
        case actions.AT_CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case actions.AT_CHANGE_PASSWORD_SUCCESS: {
            const { message } = action.payload;
            AlertSuccess(message);            
            return {
                ...state,
                isLoading: false
            };
        }
        case actions.AT_CHANGE_PASSWORD_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                isLoading: false
            };
        }
        // update profile
        case actions.AT_UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case actions.AT_UPDATE_PROFILE_SUCCESS: {
            AlertSuccess(action.payload);            
            return {
                ...state,
                isLoading: false
            };
        }
        case actions.AT_UPDATE_PROFILE_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                isLoading: false
            };
        }
        // get profile
        case actions.AT_USER_GET_PROFILE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case actions.AT_USER_GET_PROFILE_SUCCESS: {
            // const { id, email, first_name, last_name, groups, permissions, profile, video_course_is_paid, video_course_payment_log_all } = action.playload;
            // let groups_ = groups.split(";"); 
            // let permissions_ = permissions.split(";");
            // let groups_with_permissions_ = {};
            // groups_with_permissions.forEach(element => {
            //     let _group = element.name;
            //     let _permissions = [];
            //     element.permissions.forEach(element_1 => {
            //         _permissions.push(element_1.name)
            //         groups_with_permissions_[_group] = {
            //             "group": [_group],
            //             "permissions":_permissions
            //         }
            //     });
            // });             
            return {
                ...state,
                isLoading: false,
                profile: {
                    ...action.playload,
                    // id,
                    // email,
                    full_name: action.playload.first_name + ' ' + action.playload.last_name,
                    // first_name,
                    // last_name,
                    // groups,
                    // permissions,
                    // profile,
                    // video_course_is_paid,
                    // video_course_payment_log_all
                },
            };
        }
        case actions.AT_USER_GET_PROFILE_FAILURE:
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,                
                isLoading: false,
                profile: null,
            };
        case actions.AT_USER_SET_PROFILE_NULL:
            return {
                ...state,                
                isLoading: false,
                profile: null,
            };
        default:
            return state;
    }
};

export default myReducer;
