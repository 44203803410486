import { lazy } from "react";

import { ROLES } from "Utils/roleAndPermUtils";
import * as URLS from "Constants/routerConstants";

import {DashboardWrapper} from 'Backend/Pages/Dashboard/DashboardWrapper';

const BackendLayout = lazy(() => import('Backend/Layout/BackendLayout'));
// const DashboardPage = lazy(() => import('Backend/Pages/Dashboard/DashboardWrapper'));
const ProfilePage = lazy(() => import('Backend/Pages/Profile/ProfilePage'));
const ChangePasswordPage = lazy(() => import('Backend/Pages/ChangePassword'));

// const ReportsPage = lazy(() => import('Backend/Pages/Statistic/Reports/ReportsPage'));
// const ChartsPage = lazy(() => import('Backend/Pages/Statistic/Charts/ChartsPage'));
const NotAuthorizationPage = lazy(() => import('Backend/Pages/NotAuthorization/NotAuthorizationPage'));
// const MyPermissionPage = lazy(() => import('Backend/Pages/MyPermission/MyPermissionPage'));
// const MyGroupPage = lazy(() => import('Backend/Pages/MyGroup/MyGroupPage'));
const NotFoundPage = lazy(() => import('Backend/Pages/NotFoundPage/NotFoundPage'));
const MyAccountPage = lazy(() => import('Backend/Pages/MyAccount/AccountPage'))
const ProjectsPage = lazy(() => import('Backend/Pages/Projects/ProjectsPage'))
const RecommendationsToDoPage = lazy(() => import('Backend/Pages/RecommendationsToDo/RecommendationsToDoPage'))

// payment 
// const PaymentHistoryPage = lazy(() => import('Backend/Pages/PaymentHistory'));
// const PaymentOptionsPage = lazy(() => import('Backend/Pages/PaymentOptions'));
// const BillingAndPaymentPage = lazy(() => import('Backend/Pages/BillingAndPayment'));

const routers = [
  
  {
    path: URLS.DASHBOARD_URL,
    name: "Dashboard",
    component: DashboardWrapper,
    layout: BackendLayout,
  },
  // =============== My Account ==============
  {
    path: URLS.PROFILE_URL,
    name: "Profile",
    component: ProfilePage,
    layout: BackendLayout,
  },
  {
    path: URLS.MY_ACCOUNT_URL,
    name: "My Account",
    component: MyAccountPage,
    layout: BackendLayout,
    useStripe: true,
  },
  {
    path: URLS.PROJECT_URL,
    name: "Projects",
    component: ProjectsPage,
    layout: BackendLayout,
    pageClass: 'projects-page'
  },
  {
    path: URLS.CHANGE_PASSWORD_URL,
    name: "Change Password",
    component: ChangePasswordPage,
    layout: BackendLayout,
  },
  {
    path: URLS.RECOMEMMENDATION_TO_DO_URL,
    name: "Recommendations To Do",
    component: RecommendationsToDoPage,
    layout: BackendLayout,
  },
  // payment for member only
  // {
  //   path: URLS.PAYMENT_HISTORY_URL,
  //   name: "Payment History",
  //   component: PaymentHistoryPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.MEMBER],
  // },
  // {
  //   path: URLS.PAYMENT_OPTIONS_URL,
  //   name: "Payment Options",
  //   component: PaymentOptionsPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.MEMBER],
  // },
  // {
  //   path: URLS.BILLING_PAYMENT_URL,
  //   name: "Billing And Payment",
  //   component: BillingAndPaymentPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.MEMBER],
  // },


  // user
  // {
  //   path: "/my-group",
  //   name: "My Group",
  //   component: MyGroupPage,
  //   layout: BackendLayout,
  // },
  // {
  //   path: "/my-permission",
  //   name: "My Permission",
  //   component: MyPermissionPage,
  //   layout: BackendLayout,
  // },
  // {
  //   path: "/reports",
  //   name: "Report",
  //   component: ReportsPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.MEMBER, ROLES.COACH],
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   component: ChartsPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.COACH],
  // },
  {
    path: URLS.NO_PERMISSION_URL,
    component: NotAuthorizationPage,
    layout: BackendLayout,
  },
  {
    component: NotFoundPage,
    layout: BackendLayout,
  },
];

export default routers;