import React, { useEffect, Suspense } from "react";
import { Switch, BrowserRouter, HashRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import {I18nProvider} from '_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '_metronic/layout/core'
import {MasterInit} from '_metronic/layout/MasterInit'

//
import history from "Utils/historyUtils";

import frontendRouters from "Routes/FrontendRouteList";
import FrontendRoute from "Routes/FrontendRoute";
//
import backendRouters from "Routes/BackendRouteList";
import AdminRoute from "Routes/AdminRoute";

import FrontendLayout from "Frontend/Layout/FrontendLayout";
import NotFoundPage from "Frontend/Pages/NotFoundPage/NotFoundPage";

import { getUserProfileRequestAction } from "Store/Actions/userAction";

const Main = (props) => {
    useEffect(() => {
        if(!!props.isAuthenticated){
            props.getUserProfile()
        }
    }, [props, props.isAuthenticated])

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <HashRouter>
            {/* <BrowserRouter history={history}> */}
                <I18nProvider>
                    <LayoutProvider>
                        <Switch>
                            {/* Frontend Route============================================== */}
                            {
                                frontendRouters.map((v, k) => (
                                    <FrontendRoute key={k} {...v} />
                                ))
                            }
                            {/* Backend Route============================================== */}
                            {
                                backendRouters.map((v, k) => (
                                    <AdminRoute key={k} {...v} />
                                ))
                            }
                            {/* Not Found Page============================================== */}
                            <FrontendRoute component={NotFoundPage} layout={FrontendLayout} />
                        </Switch>
                        <MasterInit/>
                        <ToastContainer />
                    </LayoutProvider>
                </I18nProvider>
            {/* </BrowserRouter> */}
            </HashRouter>
        </Suspense>
    );
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.authentication.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
	getUserProfile: (data) => {
		dispatch(getUserProfileRequestAction());
	},
});

const withCompose = compose(
	connect(mapStateToProps, mapDispatchToProps)
);

export default withCompose(Main);
