import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import logo_img from "Assets/images/golftec_logo.png"

class NotFoundPage extends Component {
	render() {
		return (
			<div className="row justify-content-center">
				<div className="col-md-6">
					<div className="clearfix">
						<div className="text-center pt-4 pb-4">
							<img src={logo_img} className="c-sidebar-brand-full" height={50} alt=""/>
						</div>
						<h1 className="float-left display-3 mr-4">404</h1>
						<h4 className="pt-3">Oops! You{'\''}re lost.</h4>
						<p className="text-muted float-left">The page you are looking for was not found.</p>
					</div>
					{/* <CInputGroup className="input-prepend">
						<CInputGroupPrepend>
							<CInputGroupText>
								<CIcon name="cil-magnifying-glass" />
							</CInputGroupText>
						</CInputGroupPrepend>
						<CInput size="16" type="text" placeholder="What are you looking for?" />
						<CInputGroupAppend>
							<CButton color="info">Search</CButton>
						</CInputGroupAppend>
					</CInputGroup> */}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.authentication.isAuthenticated,
});

NotFoundPage.propTypes = {
	isAuthenticated: PropTypes.bool,
};

export default connect(mapStateToProps, null)(NotFoundPage);
